<!-- eslint-disable vue/no-v-html -->
<template>
	<b-row>
		<b-col
			v-if="!isSmallScreen"
			cols="2" />
		<b-col
			sm="10"
			md="8">
			<div
				v-if="fieldCode === 'profile_photo'"
				class="mx-auto text-center mb-3"
				:style="{
					width: isSmallScreen ? '100%' : '60%',
				}">
				<div
					v-if="activeEdit"
					class="mb-3">
					<vue-avatar
						ref="vueavatar"
						:width="profileImage.WIDTH"
						:height="profileImage.HEIGHT"
						:border-radius="profileImage.ROUNDED"
						:scale="Number(scale)"
						@select-file="onImageReady" />
					<div class="form-group mt-2">
						<label
							class="font-weight-bold"
							for="zoomControl">{{ translate('zoom') }}</label>
						<input
							id="zoomControl"
							v-model="scale"
							type="range"
							class="form-control-range custom-range"
							min="1"
							max="3"
							step="0.02">
					</div>
					<div class="row">
						<div class="col">
							<b-button
								:disabled="!imageReady"
								class="mx-1 mt-1"
								variant="primary"
								@click="saveClicked">
								{{ translate('save') }}
							</b-button>
							<b-button
								class="mx-1 mt-1"
								variant="secondary"
								@click="activeEdit = false; imageReady = false;">
								{{ translate('cancel') }}
							</b-button>
						</div>
					</div>
				</div>
				<a
					v-show="!activeEdit"
					class="image mb-3 col-12"
					@click="activeEdit = true"
					@mouseover="profilePictureHovered = true"
					@mouseleave="profilePictureHovered = false">
					<div class="square mx-auto">
						<div class="image-container">
							<img
								id="avatar"
								alt="avatar"
								class="img-avatar border border-secondary w-100">
							<span class="case" />
						</div>
					</div>
					<div
						v-if="profilePictureHovered"
						class="text pointer">
						<div class="text-white">
							<i
								class="fa fa-camera fa-3x"
								aria-hidden="true" />
							<h3>{{ translate('update') }}</h3>
						</div>
					</div>
				</a>
				<div
					v-if="value"
					class="btn btn-danger mx-1 pointer"
					@click="deletePhoto">
					{{ translate('delete_image') }}
				</div>
			</div>
			<b-form-group
				v-else
				:label="label"
				:label-for="name"
				:class="{
					'mb-3': !['sm', 'xs'].includes(windowWidth) || !showEditOption,
					'mb-0': ['sm', 'xs'].includes(windowWidth) && showEditOption,
				}">
				<template v-if="passwordKeys.includes(fieldCode)">
					<input-text
						:id="name"
						:label="label"
						:setter-value="form.password"
						:errors="errors"
						:required="true"
						:icon="fieldOptions[fieldCode].icon"
						type="prepend_password"
						@dataChanged="form.password = $event; updateForm()" />
					<input-text
						:id="`${name}_confirmation`"
						:label="translate('password_confirmation')"
						:setter-value="form.password_confirmation"
						:errors="errors"
						:required="true"
						:icon="fieldOptions['password'].icon"
						type="prepend_password"
						@dataChanged="form.password_confirmation = $event; updateForm()" />
				</template>
				<template v-else-if="dropdownKeys.includes(fieldCode)">
					<select-component
						:id="name"
						:setter-value="initialValue"
						:errors="errors"
						:disabled="disabledField"
						:class="errors.length > 0 ? 'is-invalid' : ''"
						:options="options"
						@dataChanged="value = $event; handleFieldUpdate($event)" />
				</template>
				<b-input-group
					v-else-if="telComponent.includes(fieldCode) && !disabledField"
					:id="name"
					:aria-label="label">
					<b-input-group-prepend>
						<b-input-group-text>
							<i :class="fieldOptions[fieldCode].icon" />
						</b-input-group-text>
					</b-input-group-prepend>
					<!-- <b-form-input
						v-if="!telComponent.includes(fieldCode) || disabledField"
						v-model.trim="value"
						:disabled="disabledField"
						:class="errors.length > 0 ? 'is-invalid' : ''"
						:placeholder="placeholder"
						:name="name"
						type="text"
						@input="errors = []" /> -->
					<vue-tel-input
						:id="name"
						ref="mobileNumberInput"
						v-model="value"
						:placeholder="translate('enter_phone_number')"
						v-bind="bindProps"
						:class="errors.length > 0 ? 'is-invalid' : ''"
						name="setting"
						class="form-control rounded-1" />
				</b-input-group>
				<input-text
					v-else
					:id="name"
					:key="inputKey"
					:disabled="disabledField"
					:setter-value="initialValue"
					:errors="errors"
					:required="true"
					:icon="fieldOptions[fieldCode].icon"
					:placeholder="placeholder"
					:name="name"
					:type="fieldOptions[fieldCode].type || 'prepend_text'"
					@dataChanged="value = $event" />
				<template v-if="typeof fieldOptions[fieldCode].info_message != 'undefined'">
					<span
						class="mb-1"
						:class="typeof fieldOptions[fieldCode].info_class != 'undefined' ? fieldOptions[fieldCode].info_class : {
							'text-danger': forceChange,
							'text-info': !forceChange
						}"
						v-html="translate(fieldOptions[fieldCode].info_message + (forceChange ? '_force' : ''))" />
				</template>
				<template v-if="errors.length > 0">
					<span
						v-for="error in errors"
						:key="error"
						class="invalid-feedback animated fadeIn"
						v-text="error" />
				</template>
			</b-form-group>
		</b-col>
		<b-col
			class="mx-0 px-0"
			sm="2"
			md="2">
			<span
				v-if="!activeEdit && showEditOption"
				class="btn btn-link float-left pointer mt-1"
				:class="{
					'mt-4': !isSmallScreen,
					'mb-2 float-right': isSmallScreen,
				}"
				@click="activateEdit">
				{{ translate('edit') }}
			</span>
			<template
				v-if="activeEdit && showEditOption">
				<div
					:class="{
						'mt-4 px-0': !isSmallScreen,
						'mb-2 float-right': isSmallScreen,
					}">
					<span
						class="btn btn-primary float-left border-round-left pointer mt-1"
						@click="handleSave()">{{ translate('save') }}</span>
					<span
						class="btn btn-secondary float-left border-round-right pointer mt-1"
						@click="handleCancel()">{{ translate('cancel') }}</span>
				</div>
			</template>
		</b-col>
		<phone-verification-modal
			v-if="telComponent.includes(fieldCode)"
			v-show="isPhoneVerificationRequired"
			:phone-number="form[fieldCode]"
			:open.sync="openPhoneVerificationModal"
			recaptcha-action-name="checkout_verification"
			@verified="handlePhoneVerified"
			@changeNumber="focusMobileNumberInput"
			@phoneValidationError="focusMobileNumberInput" />
	</b-row>
</template>

<script>

import { VueTelInput } from 'vue-tel-input';
import { VueAvatar } from 'vue-avatar-editor-improved';
import { ForceUsernameChange, Profile as ProfileMsg, Onboarding as OnboardingMsg } from '@/translations';
import WindowSizes from '@/mixins/WindowSizes';
import User from '@/util/User';
import {
	FIELD_OPTIONS, SHOW_EDIT_BUTTON_FIELDS, DEFAULT_NON_EDITABLES_FIELDS,
	EMAIL_KEYS, PASSWORD_KEYS, DROPDOWN_KEYS,
} from '@/settings/Onboarding';
import {
	TEL_COMPONENT as telComponent,
	PROFILE_DIMENSIONS as profileImage, DEFAULT_PROFILE_PICTURE_SIZE, PROFILE_PICTURE_FIELD_NAME, // DEFAULT_PROFILE_PICTURE_SIZE, PROFILE_PICTURE_FIELD_NAME
} from '@/settings/Profile';
import PhoneVerificationModal from '@/components/PhoneVerificationModal/index';
import { PHONE_VERIFICATION_STATES, VERIFY_PHONE_COUNTRIES } from '@/settings/PhoneVerification';
import PhoneVerification from '@/util/PhoneVerification';
import InputText from '@/components/InputText';
import Onboarding from '@/util/Onboarding';
import { WHITE_LIST_MIME_IMAGES as whiteList } from '@/settings/Images';
import Profile from '@/util/Profile';
import EventBus from '@/util/eventBus';
import SelectComponent from '@/components/Select';

const defaultImage = require('@/assets/images/common/defaultAvatar.png');

export default {
	name: 'OnboardingDynamicField',
	messages: [ForceUsernameChange, ProfileMsg, OnboardingMsg],
	components: {
		SelectComponent, InputText, PhoneVerificationModal, VueTelInput, VueAvatar,
	},
	mixins: [WindowSizes],
	props: {
		fieldCode: {
			type: String,
			default: '',
		},
		label: {
			type: String,
			default: '',
		},
		name: {
			type: String,
			default: '',
		},
		initialValue: {
			type: [String, Number],
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		forceChange: {
			type: Boolean,
			default: false,
		},
		importErrors: {
			type: Object,
			default: () => ({}),
		},
		stepCode: {
			type: String,
			default: '',
		},
		dropdownOptions: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			value: '',
			defaultValue: '',
			form: {},
			inputKey: 0,
			// Settings
			fieldOptions: FIELD_OPTIONS,
			showButtonFields: SHOW_EDIT_BUTTON_FIELDS,
			defaultDisabledFields: DEFAULT_NON_EDITABLES_FIELDS,
			emailKey: EMAIL_KEYS,
			passwordKeys: PASSWORD_KEYS,
			dropdownKeys: DROPDOWN_KEYS,
			telComponent,
			// state
			profile: new Onboarding(),
			phoneVerification: new PhoneVerification(),
			alert: new this.$Alert(),
			infoToUpdate: new User(),
			activeEdit: false,
			disabledField: false,
			// Profile image
			profileImage,
			scale: 1,
			imageReady: false,
			profilePictureHovered: false,
			defaultImage,
			profilePic: new Profile(),
			// Phone Verification
			bindProps: {
				inputOptions: {
					showDialCode: true,
				},
				disabledFetchingCountry: true,
			},
			openPhoneVerificationModal: false,
			verificationPhoneToken: '',
			mobileNumberVerified: '',
			currentVerificationState: PHONE_VERIFICATION_STATES.VERIFICATION_REQUIRED,
		};
	},
	computed: {
		errors: {
			get() {
				const imported = this.importErrors[this.fieldCode] ?? [];
				try {
					const responseErrors = this.profile.data.errors.errors[this.fieldCode] ?? [];
					return [...imported, ...responseErrors];
				} catch (error) {
					return imported;
				}
			},
			set(newErrors) {
				this.importErrors[this.fieldCode] = newErrors;
				this.profile.data.errors.errors[this.fieldCode] = newErrors;
			},
		},
		showEditOption() {
			return this.showButtonFields.includes(this.fieldCode);
		},
		isPhoneVerificationRequired() {
			return VERIFY_PHONE_COUNTRIES.includes(this.country)
				&& !this.isTheSameMobileNumber();
		},
		isOrganizationVerified() {
			return this.phoneVerification.data.response.data.response.is_verified;
		},
		isSmallScreen() {
			return ['xs', 'sm'].includes(this.windowWidth);
		},
		options() {
			if (this.fieldCode === 'language_preference_id') {
				const options = [];
				this.dropdownOptions.forEach((option) => {
					options.push({
						value: option.id,
						text: option.name,
					});
				});
				return options;
			}
			return [];
		},
	},
	watch: {
		value() {
			if ((this.value !== this.defaultValue || this.forceChange) && !this.showEditOption) {
				this.form[this.fieldCode] = this.value;
			} else if (this.value === this.defaultValue) {
				this.form[this.fieldCode] = undefined;
			}
			this.errors = [];
			this.updateForm();
		},
	},
	mounted() {
		this.value = this.initialValue;
		this.defaultValue = this.initialValue;
		this.disabledField = this.defaultDisabledFields.includes(this.fieldCode);
		this.bindProps.defaultCountry = this.country;
		this.bindProps.onlyCountries = [this.country];
		this.mobileNumberVerified = this.initialValue;
		this.loadInitialValue();
	},
	methods: {
		loadInitialValue() {
			if (this.fieldCode === 'profile_photo') {
				if (this.value) {
					document.getElementById('avatar').src = `${this.value}?v=${new Date().getTime()}`;
				} else {
					document.getElementById('avatar').src = this.defaultImage;
				}
			}
		},
		activateEdit() {
			this.activeEdit = true;
			this.disabledField = false;
		},
		handleSave() {
			this.editProfile();
		},
		handleCancel() {
			this.resetField();
			this.value = this.defaultValue;
			this.inputKey += 1;
		},
		resetField() {
			this.activeEdit = false;
			this.disabledField = true;
			this.errors = [];
		},
		updateForm() {
			this.form.step = 'update';
			this.$emit('onUpdate', this.form);
		},
		handleFieldUpdate(newValue) {
			if (this.fieldCode === 'language_preference_id') {
				const languageCode = this.dropdownOptions.find((option) => option.id === newValue);
				if (languageCode) {
					this.language = languageCode.code;
				}
			}
		},
		async editProfile() {
			const formVal = {
				step: 'update',
			};
			formVal[this.fieldCode] = this.value;
			if (this.emailKey.includes(this.fieldCode)) {
				this.profile.updateOnboardingFields(formVal, this.stepCode).then(() => {
					this.alert.confirmation(
						this.translate('swal_info'),
						this.translate('swal_info_sent_email'),
						{
							config: {
								showCancelButton: false,
								icon: 'warning',
							},
						},
						false,
					);
					this.resetField();
				}).catch(() => {});
			} else if (this.telComponent.includes(this.fieldCode)) {
				if (this.value === this.defaultValue) {
					this.handleCancel();
					return;
				}
				// If it is necessary to verify the phone number, show the modal instead of saving the new value.
				await this.phoneVerification.isOrganizationVerified(this.$user.details().id);
				if (this.isPhoneVerificationRequired && this.telComponent.includes(this.fieldCode) && !this.isOrganizationVerified) {
					this.profile.updateProfile(this.$user.details().id, { mobile_number: this.value, step: 'validate' }).then(() => {
						this.openPhoneVerificationModal = true;
					}).catch(() => {});
				}

				const form = {
					mobile_number: this.value,
					verification_token: this.verificationPhoneToken,
				};
				this.profile.updateOnboardingFields(form, this.stepCode).then(() => {
					this.defaultValue = this.value;
				}).catch(() => {});
			}
		},
		// Phone verification
		isTheSameMobileNumber() {
			return String(this.mobileNumberVerified).replaceAll(' ', '').localeCompare(String(this.value).replaceAll(' ', '')) === 0;
		},
		handlePhoneVerified(token) {
			this.verificationPhoneToken = token;
			this.mobileNumberVerified = this.value ?? '';
			// Save the value when the phone is verified
			this.handleSave();
		},
		focusMobileNumberInput() {
			this.$nextTick(() => {
				this.$refs.mobileNumberInput.focus();
			});
		},
		// Profile image
		onImageReady(event) {
			this.imageReady = whiteList.includes(event[0].type);
		},
		dataURItoBlob(dataURI) {
			let byteString;
			if (dataURI.split(',')[0].indexOf('base64') >= 0) {
				byteString = atob(dataURI.split(',')[1]);
			} else {
				byteString = unescape(dataURI.split(',')[1]);
			}
			const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
			const ia = new Uint8Array(byteString.length);
			for (let i = 0; i < byteString.length; i += 1) {
				ia[i] = byteString.charCodeAt(i);
			}
			return new Blob([ia], { type: mimeString });
		},
		saveClicked() {
			if (this.imageReady) {
				const img = this.$refs.vueavatar.getImageScaled();
				const formData = new FormData();
				const blob = this.dataURItoBlob(img.toDataURL());
				formData.append('profile_photo', blob);
				this.profilePic.updateProfilePicture(this.$user.details().id, formData).then(() => {
					this.profilePic.getProfilePhoto(this.$user.details().id, DEFAULT_PROFILE_PICTURE_SIZE).then((response) => {
						const newPhoto = {};
						newPhoto[PROFILE_PICTURE_FIELD_NAME] = `${response.attributes[PROFILE_PICTURE_FIELD_NAME]}?rand=${parseInt(((1 / Math.random()) * 1000000000), 10)}`;
						this.$user.setUser(newPhoto);
						this.$forceUpdate();
					});
					this.imageReady = false;
				}).finally(() => {
					try {
						this.alert.toast('success', this.translate('image_updated'));
					} catch (error) {
						this.alert.toast('success');
					}
					this.getData();
					EventBus.$emit('updateAccountSteps');
				});
				this.activeEdit = false;
			}
		},
		deletePhoto() {
			this.alert.confirmation(this.translate('delete_profile_picture_title'), this.translate('delete_profile_picture_text'), { confirmButtonText: this.translate('ok'), cancelButtonText: this.translate('cancel') }, false).then(() => {
				this.profilePic.deleteProfilePicture(this.$user.details().id).then(() => {
					const newPhoto = {};
					newPhoto[PROFILE_PICTURE_FIELD_NAME] = '';
					this.$user.setUser(newPhoto);
				}).finally(() => {
					try {
						this.alert.toast('success', this.translate('image_reset'));
					} catch (error) {
						this.alert.toast('success');
					}
					this.getData();
					EventBus.$emit('updateAccountSteps');
				});
				this.activeEdit = false;
			}).catch(() => {});
		},
	},
};
</script>

<style>
.image {
	position: relative;
	display: inline-block;
}
.image .case {
	position: absolute;
	left: 0;
	top: 0;
	display: inline-block;
	height: 100%;
	width: 100%;
	border-radius: 50em;
	transition: all 0.3s ease-out;
}

.image:hover .case {
	background-color: rgba(0, 0, 0, 0.7);
}

.image .text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

#avatarEditorCanvas {
	width: 70%;
	height: auto;
}
</style>
